<template>
  <main
    v-if="film"
    class="film"
    itemscope
    :itemtype="`${film.type === 1 ? 'http://schema.org/Movie' : 'http://schema.org/TVSeries' }`"
  >
    <SH :type="`db`" />

    <div class="breadcrumbs" itemscope itemtype="http://schema.org/BreadcrumbList">
      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <nuxt-link :to="localePath('/')" itemprop="item">
          <span itemprop="name">{{ $t('breadcrumbs_home') }}</span>
        </nuxt-link>
        <meta itemprop="position" content="0">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="localePath('/catalog')" itemprop="item">
          <span itemprop="name">{{ $t('breadcrumbs_catalog') }}</span>
        </nuxt-link>
        <meta itemprop="position" content="1">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="localePath(`/catalog?page=1&type=${type}`)" itemprop="item">
          <span itemprop="name">{{ $t(type) }}</span>
        </nuxt-link>
        <meta itemprop="position" content="2">
      </div>

      <div v-if="film.material_data.genres" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <nuxt-link :to="localePath(`/catalog?page=1&type=${type}&genre=${
          film.material_data.genres
            ? film.material_data.genres[0] === 'аниме'
              ? film.material_data.genres[2]
              : film.material_data.genres[0]
            : ''
        }`)" itemprop="item">
          <span itemprop="name">
            {{ film.material_data.genres
              ? film.material_data.genres[0] === 'аниме'
                ? lang === 'en' ? translateGenre([film.material_data.genres[2]]) : film.material_data.genres[2]
                : lang === 'en' ? translateGenre([film.material_data.genres[0]]) : film.material_data.genres[0]
              : ''
            }}
          </span>
        </nuxt-link>
        <meta itemprop="position" content="3">
      </div>

      <div itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
        <i class="icon-arrow" />
        <span itemprop="name">{{ ['ru', 'uk', 'be'].includes(lang) ? film.title_ru : film.title_en || film.title_ru }}</span>
        <meta itemprop="position" content="4">
      </div>
    </div>

    <section class="top">
      <div class="header">
        <div class="film-poster">
          <div class="poster" itemprop="thumbnail" itemscope itemtype="http://schema.org/ImageObject">
            <meta itemprop="contentUrl" :content="filmPoster(film)">
            <img :data-src="filmPoster(film)" src="/static_files/no-poster.png" class="lazyload" :alt="film.title_ru" loading="lazy">
          </div>
          <div class="actions">
            <Favs :payload="{
              film: {
                title: film.title_ru, title_en: film.title_en, poster: filmPoster(film),
                kinopoisk_id: film.kinopoisk_id, id: film.kinopoisk_id
              },
              button: true,
              blocked: isBlocked
            }" />
            <nuxt-link
              v-if="!isBlocked"
              :to="localePath(`/vmeste?id=${film.kinopoisk_id}`)"
              tag="button"
              class="wi watch"
            >
              <img v-if="isDesktop" src="/static_files/icons/view.png" alt="">
              {{ $t('watch') }}
            </nuxt-link>
            <button
              v-if="user && user?.tg_notifi_release.includes(film.kinopoisk_id)"
              @click="removeRelease(film.kinopoisk_id)"
              class="wi"
            >
              <img src="/static_files/icons/bell-red.png" alt="">
              <template v-if="isDesktop">
                {{ $t('followed') }}
              </template>
            </button>
            <button
              v-else
              @click="addRelease(film.kinopoisk_id)"
              class="wi"
            >
              <img src="/static_files/icons/bell.png" alt="">
              <template v-if="isDesktop">
                {{ $t('follow') }}
              </template>
            </button>
          </div>
        </div>
        <div
          v-if="!isDesktop"
          class="film-rating"
          itemprop="aggregateRating"
          itemscope
          itemtype="http://schema.org/AggregateRating"
        >
          <meta itemprop="worstRating" content="1">
          <meta itemprop="bestRating" content="10">

          <meta v-if="film.material_data.kinopoisk_rating" :content="film.material_data.kinopoisk_rating" itemprop="ratingValue">
          <meta v-else-if="film.material_data.imdb_rating" :content="film.material_data.imdb_rating" itemprop="ratingValue">
          <meta v-else :content="5" itemprop="ratingValue">

          <meta v-if="film.material_data.kinopoisk_votes" :content="film.material_data.kinopoisk_votes" itemprop="ratingCount">
          <meta v-else-if="film.material_data.imdb_votes" :content="film.material_data.imdb_votes" itemprop="ratingCount">
          <meta v-else :content="genVotes(film.kinopoisk_id)" itemprop="ratingCount">
          
          <div v-if="film.material_data.kinopoisk_rating">
            <strong>
              {{ film.material_data.kinopoisk_rating ? film.material_data.kinopoisk_rating : 'Нет рейтинга' }}
            </strong>
            <span>
              {{ genVotes(film.kinopoisk_id) }} голосов
            </span>
          </div>
          <div v-else-if="film.material_data.imdb_rating">
            <strong>
              {{ film.material_data.imdb_rating ? film.material_data.imdb_rating : 'Нет рейтинга' }}
            </strong>
            <span>
              {{ genVotes(film.kinopoisk_id) }} голосов
            </span>
          </div>
          <div>
            <b @click="goTo('reviews')">{{ $t('reviews') }}</b>
          </div>
          <!-- <img style="margin-top: 20px;" src="https://avatars.mds.yandex.net/get-direct-picture/117537/00khXNThpX8nylkNKWHtAQ/orig" alt=""> -->
        </div>
      </div>
      <div class="film-meta" itemprop="video" itemscope>
        <link itemprop="url" :href="fullPath">
        <meta itemprop="alternativeHeadline" :content="film.title_ru">
        <meta itemprop="dateReleased" content="2021-09-09T00:01:00">
        <meta itemprop="inLanguage" content="ru">
        <meta itemprop="isFamilyFriendly" content="true">
        
        <h1 itemprop="name">{{ ['ru', 'uk', 'be'].includes(lang) ? film.title_ru : film.title_en || film.title_ru }}</h1>
        <div class="alt-name">
          <template v-if="!['en'].includes(lang)">{{ film.title_en}}</template>
          <span v-if="film.material_data.minimal_age">{{ film.material_data.minimal_age }}</span>
        </div>
        <div class="tagline">
          {{ film.material_data.tagline }}
        </div>

        <!-- <ul>
          <li v-if="film.material_data.anime_studios && film.material_data.anime_studios.length">
            Студия:
            <strong>
              {{ film.material_data.anime_studios.join(', ') }}
            </strong>
          </li>
        </ul> -->

        <ul>
          <li v-if="film.material_data.genres">
            {{ $t('genre') }}:
            <div>
              <nuxt-link
                v-for="g in film.material_data.genres"
                :key="`genres-${g}`"
                :to="localePath(`/catalog?page=1&genre=${g}`)"
                style="text-transform:capitalize;"
              >
                {{ g }}
              </nuxt-link>
            </div>
          </li>
          <li v-if="film.material_data.duration">
            {{ $t('duration') }}:
            <strong>
              {{ film.material_data.duration | filterDuration }}
            </strong>
          </li>
          <li v-if="film.material_data.kinopoisk_rating">
            {{ $t('rating_kinopoisk') }}:
            <strong>{{ ('' + film.material_data.kinopoisk_rating).slice(0, 3) }}</strong>
          </li>
          <li v-if="film.material_data.imdb_rating">
            {{ $t('rating_imdb') }}:
            <strong>{{ ('' + film.material_data.imdb_rating).slice(0, 1) + (('' + film.material_data.imdb_rating).slice(1) || '.0') }}</strong>
          </li>
          <li v-if="film.material_data.premiere_ru">
            {{ $t('premiere_ru') }}:
            <strong>{{ film.material_data.premiere_ru | filterShortDate }}</strong>
          </li>
          <li v-if="film.material_data.premiere_world">
            {{ $t('premiere_world') }}:
            <strong>{{ film.material_data.premiere_world | filterShortDate }}</strong>
          </li>
          <li v-if="film.material_data.rating_mpaa">
            PG:
            <strong>{{ film.material_data.rating_mpaa }}</strong>
            <sup v-if="parseInt(film.material_data.rating_mpaa)" :title="`Лицам до ${
              parseInt(film.material_data.rating_mpaa)
            } лет обязательно присутствие взрослого`">?</sup>
          </li>
          <li v-if="film.material_data.directors && film.material_data.directors.length">
            {{ $t('directors') }}:
            <strong>{{ film.material_data.directors.join(', ') }}</strong>
          </li>
          <li v-if="film.material_data.producers && film.material_data.producers.length">
            {{ $t('producers') }}:
            <strong>{{ film.material_data.producers.join(', ') }}</strong>
          </li>
          <li v-if="film.material_data.actors && film.material_data.actors.length">
            {{ $t('actors') }}:
            <div>
              <nuxt-link
                v-for="item in film.material_data.actors"
                :key="`film-actor-${item}`"
                :to="`/name/${personName(item)}?kpid=${film.kinopoisk_id}`"
              >
                {{ item }}
              </nuxt-link>
            </div>
          </li>
          <li v-if="film.material_data.title_alt">
            {{ $t('title_alt') }}:
            <strong
              v-if="film.material_data.title_alt"
              itemprop="alternativeHeadline"
            >
              - {{ film.material_data.title_alt }}
            </strong>
          </li>
        </ul>
      </div>
      <div
        v-if="isDesktop"
        class="film-rating"
        itemprop="aggregateRating"
        itemscope
        itemtype="http://schema.org/AggregateRating"
      >
        <div v-if="film.material_data">
          <meta itemprop="worstRating" content="1">
          <meta itemprop="bestRating" content="10">

          <meta v-if="film.material_data.kinopoisk_rating" :content="film.material_data.kinopoisk_rating" itemprop="ratingValue">
          <meta v-else-if="film.material_data.imdb_rating" :content="film.material_data.imdb_rating" itemprop="ratingValue">
          <meta v-else :content="5" itemprop="ratingValue">

          <meta v-if="film.material_data.kinopoisk_votes" :content="film.material_data.kinopoisk_votes" itemprop="ratingCount">
          <meta v-else-if="film.material_data.imdb_votes" :content="film.material_data.imdb_votes" itemprop="ratingCount">
          <meta v-else :content="genVotes(film.kinopoisk_id)" itemprop="ratingCount">
          
          <div v-if="film.material_data.kinopoisk_rating">
            <strong>
              {{ film.material_data.kinopoisk_rating ? film.material_data.kinopoisk_rating : 'Нет рейтинга' }}
            </strong>
            <span>
              {{ genVotes(film.kinopoisk_id) }} голосов
            </span>
          </div>
          <div v-else-if="film.material_data.imdb_rating">
            <strong>
              {{ film.material_data.imdb_rating ? film.material_data.imdb_rating : 'Нет рейтинга' }}
            </strong>
            <span>
              {{ genVotes(film.kinopoisk_id) }} голосов
            </span>
          </div>
          <div>
            <b @click="goTo('reviews')">{{ $t('reviews') }}</b>
          </div>
        </div>

        <!-- <img style="margin-top: 20px;" src="https://avatars.mds.yandex.net/get-direct-picture/117537/00khXNThpX8nylkNKWHtAQ/orig" alt=""> -->

        <div id="yandex_rtb_R-A-2003630-5" style="padding-top:20px;"></div>
      </div>
    </section>

    <LazyHydrate when-idle>
      <LastArticles :payload="{ kpid: film.kinopoisk_id }" />
    </LazyHydrate>

    <div class="catmenu">
      <ul :style="`width:900px;`">
        <li class="active">
          {{ $t('about') }}
        </li>
        <nuxt-link tag="li" :to="`/catalog/${film.kinopoisk_id}/frames`">
          {{ $t('frames') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="`/catalog/${film.kinopoisk_id}/trailers`">
          {{ $t('trailers') }}
        </nuxt-link>
        <!-- <nuxt-link tag="li" :to="`/name`" tag="li">
          {{ $t('persons') }}
        </nuxt-link> -->
        <nuxt-link tag="li" :to="`/catalog/${film.kinopoisk_id}/reviews`">
          {{ $t('reviews') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="`/catalog/${film.kinopoisk_id}/sap`">
          {{ $t('sap') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="`/catalog/${film.kinopoisk_id}/facts`">
          {{ $t('facts') }}
        </nuxt-link>
        <nuxt-link tag="li" :to="`/catalog/${film.kinopoisk_id}/related`">
          {{ $t('recommends') }}
        </nuxt-link>
      </ul>
    </div>

    <!-- <div id="yandex_rtb_R-A-1740435-2" style="padding:20px 0px;"></div> -->
    
    <section v-if="film.material_data.description">
      <h2>
        {{ $t('story') }}
      </h2>
      <p class="description" itemprop="description">{{ film.material_data.description }}</p>
    </section>
    <section v-else-if="film.description && film.description.length">
      <h2>
        {{ $t('story') }}
      </h2>
      <p class="description" itemprop="description">{{ film.description[0].text }}</p>
    </section>

    <section v-if="film.review && film.review.length">
      <h2>
        {{ $t('out_review') }}
      </h2>
      <p class="review" itemprop="description">{{ film.review[0].text }}</p>
    </section>

    <!-- <section>
      <h2>
        <a
          href="https://merch.unotalone.ru/"
          target="_blank"
          rel="nofollow noreferrer noopener"
        >Наши мерчи</a>
        <sup
          @click="$router.push('/help?p=23_01_05_0')"
          style="padding:2px 8px;
          font-size:1.2rem;
          color:#fff;
          background-color:rgba(251, 45, 127, 1);
          border-radius:10px;
          text-transform:uppercase;
          cursor:pointer;"
        >
          +5% кэшбэк
        </sup>
      </h2>
      <Merch v-if="showBlocks['6']" :title="film.title_ru" />
      <div v-else class="lazy-block" data-block-id="6" />
    </section> -->

    <section>
      <h2>
        <nuxt-link :to="localePath(`/catalog/${film.kinopoisk_id}/sap`)">
          {{ $t('sap') }}
        </nuxt-link>
      </h2>
      <FilmSap v-if="showBlocks['2']" :kpid="film.kinopoisk_id" />
      <div v-else class="lazy-block" data-block-id="2" />
    </section>

    <section>
      <h2>
        <nuxt-link :to="localePath(`/catalog/${film.kinopoisk_id}/frames`)">
          {{ $t('frames') }}
        </nuxt-link>
      </h2>
      <FilmFrames v-if="film.material_data && film.material_data.screenshots && showBlocks['0']" :payload="{ kpid: film.kinopoisk_id, screenshots: film.material_data.screenshots }" />
      <FilmFrames v-else-if="showBlocks['0']" :payload="{ kpid: film.kinopoisk_id, screenshots: [] }" />
      <div v-else class="lazy-block" data-block-id="0" />
    </section>

    <div id="yandex_rtb_R-A-2003630-4" style="padding:20px 0px;"></div>

    <!-- <section class="trailers">
      <h2>
        <nuxt-link :to="`/catalog/${film.kinopoisk_id}/trailers`">
          Трейлеры
        </nuxt-link>
      </h2>
      <FilmTrailers v-if="showBlocks['1']" :text="`фильм ${film.title_ru} трейлер`" />
      <div v-else class="lazy-block" data-block-id="1" />
    </section> -->
    
    <section>
      <h2>
        <nuxt-link :to="localePath(`/catalog/${film.kinopoisk_id}/related`)">
          {{ $t('recommends') }}
        </nuxt-link>
      </h2>
      <FilmRelated v-if="showBlocks['3']" :payload="{ kpid: film.kinopoisk_id, genre: relatedGenre }" />
      <div v-else class="lazy-block" data-block-id="3" />
    </section>

    <section>
      <h2>
        <nuxt-link :to="localePath(`/catalog/${film.kinopoisk_id}/facts`)">
          {{ $t('facts') }}
        </nuxt-link>
      </h2>
      <FilmFacts v-if="showBlocks['4']" :kpid="film.kinopoisk_id" />
      <div v-else class="lazy-block" data-block-id="4" />
    </section>

    <section>
      <h2>
        <nuxt-link :to="localePath(`/catalog/${film.kinopoisk_id}/reviews`)">
          {{ $t('reviews') }}
        </nuxt-link>
      </h2>
      <FilmReviews v-if="showBlocks['5']" :kpid="film.kinopoisk_id" />
      <div v-else class="lazy-block" data-block-id="5" />
    </section>
  </main>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import { mapState } from 'vuex'

export default {
  meta: {
    i18n: false,
  },
  components: {
    LazyHydrate,
    LastArticles: () => import('~/components/LastArticles.vue'),
    Favs: () => import('~/components/Favs.vue'),
    FilmSap: () => import('~/components/FilmSap.vue'),
    FilmReviews: () => import('~/components/FilmReviews.vue'),
    FilmFacts: () => import('~/components/FilmFacts.vue'),
    FilmFrames: () => import('~/components/FilmFrames.vue'),
    // FilmTrailers: () => import('~/components/FilmTrailers.vue'),
    FilmRelated: () => import('~/components/FilmRelated.vue'),
    // Merch: () => import('~/components/Merch.vue'),
    SH: () => import('~/components/SH.vue')
  },
  head() {
    const title = this.$i18n.locale === 'en'
        ? this.film.title_en ? this.film.title_en : this.film.title_ru
        : `${this.film.title_ru}${
        this.film.title_en ? ` / ${this.film.title_en}` : ''
      }`
    return {
      title: `${title}${
        this.film.material_data.year ? ` (${this.film.material_data.year})` : ''
      }
      ${
        this.film.material_data.countries && this.film.material_data.countries.length
          ? this.film.material_data.countries[0]
          : ''
      }
      ${
        this.film.type === 2 || this.film.type === 4
          ? ` — ${this.$t('watch_all_series')}`
          : ` — ${this.$t('watch_free_hd')}`
      } #${this.film.kinopoisk_id}`,
      // title: `[★${
      //   this.film.material_data.kinopoisk_rating
      //     ? this.film.material_data.kinopoisk_rating
      //     : this.film.material_data.imdb_rating
      //       ? this.film.material_data.imdb_rating
      //       : 10
      // }] ${this.film.title_ru}${
      //   this.film.title_en ? ` / ${this.film.title_en}` : ''
      // }${
      //   this.film.material_data.year ? ` (${this.film.material_data.year})` : ''
      // }
      // ${
      //   this.film.material_data.countries && this.film.material_data.countries.length
      //     ? this.film.material_data.countries[0]
      //     : ''
      // }
      // ${
      //   this.film.type === 2 || this.film.type === 4
      //     ? ' — смотреть онлайн все серии бесплатно на русском'
      //     : ' — смотреть онлайн бесплатно в хорошем HD качестве'
      // }`,
      // link: [
      //   {
      //     hid: 'canonical',
      //     rel: 'canonical',
      //     href: this.fullPath
      //   }
      // ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `${title} смотреть онлайн`
        },
        {
          hid: 'description',
          name: 'description',
          content: `${title}${
            this.film.material_data.year ? ` (${this.film.material_data.year})` : ''
          } ${
            this.film.material_data.countries && this.film.material_data.countries.length
              ? this.film.material_data.countries[0]
              : ''
          }: ${this.$t('film_head_description')}. #${this.film.kinopoisk_id}`
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: `film.movie`
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.filmPoster(this.film.kinopoisk_id)
        },
        {
          hid: 'og:image:type',
          property: 'og:image:type',
          content: `image/jpeg`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${this.film.title_ru}${
            this.film.title_en ? ` / ${this.film.title_en}${
              this.film.material_data.year ? ` (${this.film.material_data.year})` : ''
            }` : ''
          }`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `${
            this.film.material_data.description
              ? this.film.material_data.description.slice(0, 300)
              : ''
          }`
        }
      ]
    }
  },
  filters: {
    filterDuration(val) {
      val = Number(val);
      const h = Math.floor(val / 3600)
      const m = Math.floor(val % 3600 / 60)

      const hDisplay = h > 0 ? h + (h === 1 ? " ч. " : " ч. ") : ""
      const mDisplay = m > 0 ? m + (m === 1 ? " мин. " : " мин. ") : ""
      return hDisplay + mDisplay
    },
    filterShortDate(val) {
      const value = val
      const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря'
      ]
      const day = new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      return `${day} ${months[month]} ${year}`
    },
    filterDate(val) {
      const value = val
      const months = [
        'янв',
        'фев',
        'мар',
        'апр',
        'мая',
        'июн',
        'июл',
        'авг',
        'сен',
        'окт',
        'ноя',
        'дек'
      ]
      const day =
        new Date(value).getDate() >= 10
          ? new Date(value).getDate()
          : '0' + new Date(value).getDate()
      const month = new Date(value).getMonth()
      const year = new Date(value).getFullYear()
      const hour =
        new Date(value).getHours() >= 10
          ? new Date(value).getHours()
          : '0' + new Date(value).getHours()
      const min =
        new Date(value).getMinutes() >= 10
          ? new Date(value).getMinutes()
          : '0' + new Date(value).getMinutes()
      return `${day} ${months[month]} ${year} в ${hour}:${min}`
    }
  },
  computed: {
    ...mapState(['user', 'isDesktop']),
    lang () {
      return this.$i18n.locale
    },
    relatedGenre() {
      const genres = this.film.material_data.genres || this.film.material_data.genres
      const rand = Math.floor(Math.random() * genres.length)
      return genres[rand]
    },
    fullPath() {
      const id = this.film.kinopoisk_id
      const title = this.film.title_ru
      return 'https://unotalone.su/catalog/' + this.postUrl(id, title)
    }
  },
  async asyncData({ params, error, $axios }) {
    const id = params.id
      ? params.id.indexOf('-') > 0
        ? params.id.slice(0, params.id.indexOf('-'))
        : params.id
      : null
    if (!id) {
      error({ statusCode: 404, message: 'Материал не найден' })
    } else {
      const { data } = await $axios.get(`/api/film?id=${id}`)
      if (data.error || (data.blocked.length && data.blocked[0].status === 'blocked')) {
        return error({ statusCode: 404, message: 'Материал не найден' })
      }
      let type = ''

      switch (data.type) {
        case 1:
          type = 'films'
          break;
        case 2:
          type = 'serials'
          break;
        case 3:
          type = 'anime'
          break;
        case 4:
          type = 'anime-serials'
          break;
        case 5:
          type = 'tv-show'
          break;
      
        default:
          break;
      }
      return {
        film: data,
        type,
        types: {
          films: 'Фильмы',
          serials: 'Сериалы',
          anime: 'Аниме фильмы',
          'anime-serials': 'Аниме сериалы',
          'tv-show': 'ТВ-Шоу',
        },
        isBlocked: !!data.blocked.length,
        // type: {
        //   name: data.type === 'anime' ? 'Фильмы' : 'Многосерийные',
        //   en: data.type
        // },
        showBlocks: {
          '0': false, '1': false, '2': false, '3': false, '4': false, '5': false, '6': false
        },
        loading: false
      }
    }
  },
  mounted() {
    window.removeEventListener('keydown', this.closeEsc)
    window.addEventListener('keydown', this.closeEsc)

    setTimeout(() => {
      window.removeEventListener('scroll', this.showBlock)
      window.addEventListener('scroll', this.showBlock)
      this.showBlock()
    }, 100)

    if (!this.user || (this.user.group !== 777 && this.user.group !== 0)) {
      const rsyaVerticalHorizon = document.createElement('script')
      rsyaVerticalHorizon.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-2003630-4',
    blockId: 'R-A-2003630-4'
  })
})
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-2003630-5',
    blockId: 'R-A-2003630-5'
  })
})
`
      document.body.appendChild(rsyaVerticalHorizon)
    }
  },
  methods: {
    addRelease(id) {
      if (!this.user) return
      const user = this.user
      if (user.tg_notifi_release.includes(id)) return
      user.tg_notifi_release.push(id)
      this.$axios.post(`/api/user/tg/release`, user.tg_notifi_release)
      this.$store.commit('setUser', user)
    },
    removeRelease(id) {
      if (!this.user) return
      const user = this.user
      const release = user.tg_notifi_release.filter(el => el !== id)
      user.tg_notifi_release = release
      this.$axios.post(`/api/user/tg/release`, user.tg_notifi_release)
      this.$store.commit('setUser', user)
    },
    // async voting(op) {
    //   if (!this.user) {
    //     this.$router.push('/signin')
    //   }
    //   if (this.film[`votes_${op}`].includes(this.user.id)) {
    //     this.$store.commit('setError', { status: 'info', msg: 'Уже оценивали' })
    //     setTimeout(() => this.$store.commit('clearError'), 2000)
    //     return
    //   }
    //   const body = {
    //     kinopoisk_id: this.film.kinopoisk_id,
    //     op
    //   }
    //   const { data } = await this.$axios.post(`/api/votes`, body)
    //   if (data.error) {
    //     this.$store.commit('setError', { status: 'info', msg: this.$t('auth_needded') })
    //     setTimeout(() => this.$store.commit('clearError'), 2000)
    //   } else {
    //     this.film[`votes_${op}`].push(this.user.id)
    //   }
    // },
    genVotes(kpid) {
      let votes = 1
      const arr = kpid.split('')
      arr.forEach(el => {
        votes += +el
      })
      return +votes * +this.film.material_data.year
    },
    goTo(section) {
      // document.querySelector(`.${section}`).scrollIntoView()
      this.$router.push(`/catalog/${this.film.kinopoisk_id}/${section}`)
    },
    showBlock() {
      const list = document.querySelectorAll('.lazy-block')
      const doc = document.documentElement.clientHeight
      list.forEach(block => {
        const visible = doc - block.getBoundingClientRect().top
        const blockId = block.dataset.blockId
        if (visible > -300 && !this.showBlocks[blockId]) {
          this.showBlocks[blockId] = true
        }
      })
    }
  }
}
</script>

<style src="~/assets/film.styl" lang="stylus" />
